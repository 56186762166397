import React, { useEffect } from 'react';
import { authService, ticketEventService, customerUserService } from '../../_services'
import { useTranslation } from "react-i18next";
import { Card, Button, Modal, Form } from 'react-bootstrap'
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function Events({ticket}) {

	const [events, setEvents] = React.useState([])
	const [showModal, setShowModal] = React.useState(false)
	const [ticketEvent, setTicketEvent] = React.useState({type: 'UNDER_INVESTIGATION'})
	const {t} = useTranslation('common');

	useEffect(() => {
		setEvents(ticket.events)
    }, [])

	const formatDate = function(date) {
		return moment(date).format('YYYY-MM-DD HH:mm')
	}

	const formatEventType = function(type) {
		switch (type) {
			case 'RECEIVED':
				return t('event.types.received')
			case 'UNDER_INVESTIGATION':
				return t('event.types.underinvestigation')
			case 'RESOLVED':
				return t('event.types.resolved')
			case 'READ_BY_WHISTLEBLOWER':
				return t('event.types.readbywhistleblower')
		}
	}

	const handleChange = (e) => {
	    const target = e.target
	    const name = target.name
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    setTicketEvent( prevTicketEvent => ({
	        ...prevTicketEvent,
	        [name]: value
	    }));
	}

	const submit = function(event) {
		console.log('submit')

		event.preventDefault()
        event.stopPropagation()

		ticketEvent.customerId = ticket.customerId
		ticketEvent.userId = authService.getCurrentUser().id
		ticketEvent.ticket = ticket.id

		ticketEventService.save(ticketEvent).then(data =>  {
			events.push(data)

		    setTicketEvent({type: 'UNDER_INVESTIGATION'})
		})

		setShowModal(false)
	}

	const onHide = () => {
		console.log('onHide')
		setShowModal(false)
		//setBackdrop(false)
		document.body.className = ""
		document.body.style = '';
    }

	return (
		<div>
			{ ticket &&
				<>
					<div className="clearfix">
						<div style={{'float': 'left'}}>
							<h4>
								{t('event.label')}
							</h4>
						</div>
						<div style={{'float': 'right'}}>
							<Button
					            style={{ width: "100px" }}
					            onClick={() => setShowModal(true)}
					            title={t("crudtable.add")}
					            size="md"
					            color="info"
					            variant="outline-secondary">
					            {t("crudtable.add")} <FontAwesomeIcon icon={faPlus}/>
					        </Button>
						</div>
					</div>
					{ ticket.events.length == 0 &&
						<Card style={{margin: '10px'}}>
							<Card.Body>
								{t("event.noevents")}
							</Card.Body>
						</Card>
					}
					{ ticket.events.sort((a, b) => b.dateCreated.localeCompare(a.dateCreated)).map(event =>
						<Card style={{margin: '10px'}}  key={event.id}>
							<Card.Body>
								<div>
									<b>{t('event.columns.datecreated')}:</b> {formatDate(event.dateCreated)}
								</div>
								<div>
									<b>{t('event.columns.type')}:</b> {formatEventType(event.type)}
								</div>
								{ event.type != 'READ_BY_WHISTLEBLOWER' &&
									<>
										<div>
											<b>{t('event.columns.creator')}:</b> {event.user.username || '-'}
										</div>
										<div>
											<b>{t('event.columns.message')}:</b>
											<br/>
											{event.message}
										</div>
									</>
								}
							</Card.Body>
						</Card>
					)}
				</>
			}
			<Modal
				size="lg"
				show={showModal}
				backdrop={false}
				onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('event.label')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form id="data-form" onSubmit={submit}>
						<Form.Group controlId="type">
		                    <Form.Label>
			                    {t('event.columns.type')} *
			                </Form.Label>
							<Form.Control required as="select" name="type" value={ticketEvent.type} onChange={handleChange} required="required">
								<option value="RECEIVED">
									{t('event.types.received')}
								</option>
								<option value="UNDER_INVESTIGATION">
									{t('event.types.underinvestigation')}
								</option>
								<option value="RESOLVED">
									{t('event.types.resolved')}
								</option>
			                </Form.Control>
			            </Form.Group>
			            <Form.Group controlId="message">
			                <Form.Label>
			                    {t('event.columns.message')} *
			                </Form.Label>
			                <Form.Control required type="text" placeholder={t('event.columns.message')} name="message" onChange={handleChange}/>
			            </Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={onHide}>
						{t('crudtable.cancel')}
					</Button>
					<Button form='data-form' type="submit" variant="primary">
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
            </Modal>
		</div>
    )
}

export default Events;