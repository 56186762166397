import React, {useEffect} from 'react';
import { authService } from '../_services'
import { history } from '../_helpers';

function Home() {

	useEffect(() => {
		if (authService.hasRoles(['ROLE_TICKET_READ'])) {
			history.push("/ticket")
			//window.location.href = '/ticket';
		}
    }, [])

  return (
    <>

    </>
  )
}

export default Home;