import React, { useEffect } from 'react';
import { ticketService, authService, customerService, ticketEventService } from '../../_services'
import { useTranslation } from "react-i18next";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { SERVER_URL } from '../../config';
import { Card } from 'react-bootstrap'
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileWord, faFile, faFilePdf, faFilePowerpoint } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser'

function Items({ticket}) {

	const [map, setMap] = React.useState(null)
	const {t} = useTranslation('common');

	useEffect(() => {
    }, [])

    const getItem = function(item) {
        switch (item.type) {
            case 'TEXT_FIELD':
                return item.answer || '-'
            case 'TEXT_AREA':
                return item.answer || '-'
            case 'RADIO_BUTTONS':
                return item.answer || '-'
            case 'CHECKBOX':
                return item.answer ? formatBoolean(item.answer) : '-'
            case 'CHECKBOXES':
                return <>{item.answer.replaceAll(';', ', ')}</>
            case 'DROPDOWN':
                return item.answer || '-'
            case 'MAP':
                if (!item.answer) {
                    return '-'
                }
                var lat = parseFloat(item.answer.split(',')[0])
                var lng = parseFloat(item.answer.split(',')[1])
                return <GoogleMap
                    zoom={10}
                    center={{ lat: lat, lng: lng }}
			        mapContainerStyle={containerStyle}
			        onLoad={onLoad}
			        onUnmount={onUnmount}>
	                   <Marker
	                    position={{ lat: lat, lng: lng }}
	                    title={'cheese'}
	                  />
	            </GoogleMap>
            case 'FILE':
                return <a href={SERVER_URL + '/ticketItem/data/' + item.id} target="_blank">
	                {getFile(item)}
                </a>
        }
		return '-'
	}

	const getFile = function(item) {
		if (item.dataType) {
			if (item.dataType.indexOf('image') > -1) {
				return <img src={SERVER_URL + '/ticketItem/data/' + item.id} style={{width: '200px'}}/>
			}
			if (item.dataType == 'application/msword') {
				return <FontAwesomeIcon icon={faFileWord} size="6x"/>
			}
			if (item.dataType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
				return <FontAwesomeIcon icon={faFileWord} size="6x"/>
			}
			if (item.dataType == 'application/pdf') {
				return <FontAwesomeIcon icon={faFilePdf} size="6x"/>
			}
			if (item.dataType == 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
				return <FontAwesomeIcon icon={faFilePowerpoint} size="6x"/>
			}
			if (item.dataType == 'application/vnd.ms-powerpoint') {
				return <FontAwesomeIcon icon={faFilePowerpoint} size="6x"/>
			}
		}
		return '-'
	}

	const containerStyle = {
      width: '600px',
      height: '400px'
    }

    const onLoad = React.useCallback(function callback(map) {
	    //const bounds = new window.google.maps.LatLngBounds();
	    //map.fitBounds(bounds);
	    setMap(map)
	    console.log('onLoad')
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
		setMap(null)
	}, [])

	const formatItemType = function(type) {
		switch (type) {
			case 'TEXT_FIELD':
				return t('item.types.textfield')
			case 'TEXT_AREA':
				return t('item.types.underinvestigation')
			case 'RADIO_BUTTONS':
				return t('item.types.resolved')
			case 'CHECKBOX':
				return t('item.types.received')
			case 'DROPDOWN':
				return t('item.types.underinvestigation')
			case 'MAP':
				return t('item.types.resolved')
			case 'FILE':
				return t('item.types.received')
		}
	}

	const formatBoolean = function(value) {
		return value ? t('crudtable.yes') : t('crudtable.no')
	}

	return (
		<div>
			{ ticket &&
				<>
					<h4>
						{t('item.answers')}
					</h4>
					{ ticket.items.filter(item => item.type != 'HEADER' && item.type != 'TEXT').map(item =>
						<Card style={{margin: '10px'}} key={item.id}>
							<Card.Body>
								<div>
									<b>{parse(item.text)}</b>
								</div>
								<div>
									{getItem(item)}
								</div>
							</Card.Body>
						</Card>
					)}
				</>
			}
		</div>
    )
}

export default Items;