import api from './api.service'

export const formService = {
    getAll,
    save,
    update,
    _delete
};

const BASE_URL = '/forms'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function save(form) {
    return api.post(BASE_URL, form)
}

function update(id, form) {
    return api.put(BASE_URL + "/" + id, form)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
