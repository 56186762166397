import React, {useState, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { userService } from '../../_services'
import Select, { components } from 'react-select'

const CustomerForm = ({data, handleChange, handleSubmit, validated}) => {

    const [users, setUsers] = useState([])
    const [selectedValues, setSelectedValues] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        userService.getAll().then(users => {
            setSelectedValues(data.customerResponsibleUsers.map(customerResponsibleUser => ({id: customerResponsibleUser.responsibleUser, username: users.find(user => user.id === customerResponsibleUser.responsibleUser)}.username)))
            setUsers(users)
        })
    }, [])

    function onChange(e) {
		console.log('onChange')
		console.log(e)
		setSelectedValues(e)
		console.log(e.map(user => ({customer: user.customer, responsibleUser: user.id})))
		handleChange({target: {type: "select", name: "customerResponsibleUsers", value: e.map(user => ({customer: user.customer, responsibleUser: user.id}))}})
	}

    const NoOptionsMessage = props => {
		return (
			<components.NoOptionsMessage {...props}>
				<span className="custom-css-class">{t('member.nousers')}</span>
			</components.NoOptionsMessage>
		);
	};

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('customer.columns.name')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('customer.columns.name')}  name="name" value={data.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="responsibleUsers">
                <Form.Label>
                    {t('customer.columns.responsibleusers')}
                </Form.Label>
                <Select
					placeholder={t('member.selectmembers')}
					isLoading={users.length === 0}
					options={users}
					value={selectedValues}
					getOptionLabel={user => user.username}
					getOptionValue={user => user.id}
					isMulti={true}
					closeMenuOnSelect={false}
					onChange={onChange}
					components={{ NoOptionsMessage }}/>
            </Form.Group>
        </Form>
    )
}

export default CustomerForm