import React, {useState, useEffect} from 'react';
import { ticketService, authService } from '../../_services'
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import Members from './members'
import Messages from './messages'
import Events from './events'
import Items from './items'

function TicketDetails() {

	const [ticket, setTicket] = useState(null)
	const {uuid} = useParams();
	const {t} = useTranslation('common');

	useEffect(() => {
		ticketService.show(uuid).then(ticket => {
            setTicket(ticket)
        })
    }, [])

	return (
		<div>
			{ ticket &&
				<>
					<h2>
						{ticket.name}
					</h2>
					<Members ticket={ticket}></Members>
					<Events ticket={ticket}></Events>
					<Messages ticket={ticket}></Messages>
					<Items ticket={ticket}></Items>
				</>
			}
		</div>
    )
}

export default TicketDetails;