import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {authService} from '../_services';

function ProtectedRoute({ component: Component, permission=[], ...rest }) {
    return (
        <Route {...rest} render={props => {
            console.log('isAuthenticated: ' + authService.isAuthenticated())
            console.log('hasRoles: ' + authService.hasRoles(permission))
            if (!authService.isAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            } else if (!authService.hasRoles(permission)) {
                // does not have roles so redirect to forbidden page with the return url
                return <Redirect to={{ pathname: '/forbidden', state: { from: props.location } }} />
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export { ProtectedRoute };