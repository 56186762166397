import React, { useEffect } from "react";
import { customerUserService, ticketMemberService } from "../../_services"
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap"
import Select, { components } from 'react-select'

function Members({ticket}) {

	const [members, setMembers] = React.useState([])
	const [users, setUsers] = React.useState([])
	const {t} = useTranslation("common");

	useEffect(() => {
		setMembers(ticket.members)
		customerUserService.getAll().then(users => {
            setUsers(users)
        })
    }, [])

	function onChange(e) {
		console.log('onChange')
		console.log(members)
		console.log(e)
		let removedMembers = members.filter(x => !e.includes(x))
		console.log(removedMembers)
		let addedMembers = e.filter(x => !members.includes(x))
		console.log(addedMembers)
		setMembers(e)

		removedMembers.forEach(removedMember => {
			console.log(removedMember)
			ticketMemberService._delete(ticket.id, removedMember.id).then(data =>  {

			})
		})

		addedMembers.forEach(addedMember => {
			console.log(addedMember)
			var ticketMember = {
				ticket: ticket.id,
				user: addedMember.id
			}
			ticketMemberService.save(ticketMember).then(data =>  {

			})
		})
	}

	const NoOptionsMessage = props => {
		return (
			<components.NoOptionsMessage {...props}>
				<span className="custom-css-class">{t('member.nousers')}</span>
			</components.NoOptionsMessage>
		);
	};

	return (
		<div>
			{ ticket &&
				<>
					<h4>
						{t('member.label')}
					</h4>
					<Row style={{paddingBottom: '10px' }}>
						<Col xs="4">
							<Select
								placeholder={t('member.selectmembers')}
								isLoading={users.length === 0}
								options={users}
								value={members}
								getOptionLabel={user => user.username}
								getOptionValue={user => user.id}
								isMulti={true}
								closeMenuOnSelect={false}
								onChange={onChange}
								components={{ NoOptionsMessage }}/>
						</Col>
					</Row>
				</>
			}
		</div>
    )
}

export default Members;