import api from './api.service'

export const customerUserService = {
    getAll
};

const BASE_URL = '/customerUsers'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}
