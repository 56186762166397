import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { customerService, roleService } from '../../_services'

const UserForm = ({data, handleChange, handleSubmit, validated}) => {
    const [customers, setCustomers] = useState([])
    const [roles, setRoles] = useState([])
    const [selectedValues, setSelectedValues] = useState([])

    const {t} = useTranslation('common');

    useEffect(() => {
        customerService.getAll().then(customers => {
            setCustomers(customers)
            if (data.customer === -1 && customers.length > 0) {
                handleChange({target: {type: "select", name: "customer", value: customers[0].id}})
            }
        })
        roleService.getAll().then(result => {
            setSelectedValues(data.userRoles.map(userRole => ({id: userRole.role, authority: result.find(role => role.id === userRole.role)}.authority)))
            setRoles(result)
        })
    }, [])

    function onChange(e) {
        //Example: [{id: 10, authority: "ROLE_DEVICE_READ"}]
        setSelectedValues(e)
        handleChange({target: {type: "select", name: "userRoles", value: e.map(userRole => ({role: userRole.id}))}})
    }

    function onChangeRole(e) {
		console.log('onChangeRole')
		var role = roles.find(role => role.authority == e.target.id)
		handleChange({target: {type: "select", name: "userRoles", value: [{role: role.id}]}})
    }

    function isChecked(authority) {
        console.log('isChecked')
        var role = roles.find(role => role.authority == authority)
        if (role) {
	        var userRole = data.userRoles.find(userRole => userRole.role == role.id)
	        if (userRole) {
	            return true
	        }
        }
        return false
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="customer">
                <Form.Label>
                    {t('user.columns.customer')}
                </Form.Label>
                <Form.Control required as="select" name="customer" value={data.customer} onChange={handleChange}>
                    {customers.map(customer => (
                        <option key={customer.id} value={customer.id}>{customer.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="username">
                <Form.Label>
                    {t('user.columns.username')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('user.columns.username')} name="username" value={data.username} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="password">
                <Form.Label>
                    {t('user.columns.password')}
                </Form.Label>
                <Form.Control required type="password" placeholder={t('user.columns.password')} name="password" value={data.password} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="email">
                <Form.Label>
                    {t('user.columns.email')}
                </Form.Label>
                <Form.Control type="email" placeholder={t('user.columns.email')} name="email" value={data.email} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="roles">
                <Form.Label>
                    {t('user.columns.role')}
                </Form.Label>
                <Form.Check type="radio" id="ROLE_ADMIN" name="role" label={t('user.roles.admin')} onChange={onChangeRole} checked={isChecked('ROLE_ADMIN')}/>
                <Form.Check type="radio" id="ROLE_CUSTOMER" name="role" label={t('user.roles.customer')} onChange={onChangeRole} checked={isChecked('ROLE_CUSTOMER')}/>
            </Form.Group>
            <Form.Group controlId="enabled">
                <Form.Check type="checkbox" label={t('user.columns.enabled')} name="enabled" checked={data.enabled} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="accountExpired">
                <Form.Check type="checkbox" label={t('user.columns.accountexpired')} name="accountExpired" checked={data.accountExpired} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="accountLocked">
                <Form.Check type="checkbox" label={t('user.columns.accountlocked')} name="accountLocked" checked={data.accountLocked} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="passwordExpired">
                <Form.Check type="checkbox" label={t('user.columns.passwordexpired')} name="passwordExpired" checked={data.passwordExpired} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="sendTicketAlerts">
                <Form.Check type="checkbox" label={t('user.columns.sendticketalerts')} name="sendTicketAlerts" checked={data.sendTicketAlerts} onChange={handleChange}/>
            </Form.Group>
        </Form>
    )
}


export default UserForm