import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../_actions';
import { history } from '../_helpers';
import { Alert } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

function AlertComponent() {

    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();
    const {t} = useTranslation('common');

    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }, [dispatch]);

    if (alert.message) {
        return (
            <Alert style={{marginTop: '10px'}} variant={alert.type} onClose={() => dispatch(alertActions.clear())} dismissible>
                {t(alert.message, alert.params)} {alert.params}
            </Alert>
        )
    } else {
        return <></>
    }
}

export default AlertComponent