import axios from "axios"
import { SERVER_URL } from '../config'
import { history, authHeader } from '../_helpers'

export const authService = {
    login,
    logout,
    isAuthenticated,
    getCurrentUser,
    getToken,
    hasRoles,
    switchUser
}

function login(username, password) {
    return axios
      .post(SERVER_URL + "/login", {
        username: username,
        password: password
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data))
        }

        return response.data
      })
}

function hasRoles(roles) {
  const userRoles = new Set(getCurrentUser()?.roles || [])
  return roles.every(role => userRoles.has(role))
}

function isAuthenticated() {
    return !(localStorage.getItem("user") === null)
}

function getCurrentUser() {
  return JSON.parse(localStorage.getItem('user'))
}

function getToken() {
  return this.getCurrentUser().access_token
}

function logout(from) {
    // remove user from local storage to log user out
    localStorage.removeItem('user')
    const relay = from || history.location
    history.push("/login", { from: relay })
}

function switchUser(user) {
	var headers = {
      'Content-Type': 'application/json',
      'Authorization': authHeader()
    }
    return axios
        .get(SERVER_URL + "/switch/index/" + user, { headers: headers })
        .then(response => {
            if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data))
            }
            return response.data
        })
}
