import api from './api.service'

export const fileService = {
    update
};

const BASE_URL = '/files'

function update(id, file) {
    return api.put(BASE_URL + "/" + id, file)
}
