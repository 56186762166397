import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Form from "react-validation/build/form";
import { Container, Row, Jumbotron, Col, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { userActions } from '../_actions';
import { useDispatch, useSelector } from 'react-redux';
import AlertComponent from './alert.component'
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser'
import { environmentService } from '../_services'

function Login() {

	const {t} = useTranslation('common');
	const [environment, setEnvironment] = useState('')

	useEffect(() => {
		console.log('useEffect')
		var existingEnvironment = localStorage.getItem('environment')
		console.log(existingEnvironment)
		if (existingEnvironment) {
			setEnvironment(existingEnvironment)
			document.title = environmentService.getTitle(existingEnvironment)
		} else {
			environmentService.get().then(environment => {
	            console.log(environment)
	            setEnvironment(environment)
	            localStorage.setItem('environment', environment)
	            document.title = environmentService.getTitle(environment)
	        })
		}
    }, [])

	const required = value => {
		if (submitted && !value) {
			return (
				<div className="alert alert-danger" role="alert">
					This field is required!
				</div>
			);
		}
	};

	const [inputs, setInputs] = useState({
		username: '',
		password: ''
    });

    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const loggingIn = useSelector(state => state.authentication.loggingIn);

    const dispatch = useDispatch();
    const location = useLocation();
  
    function handleChange(e) {
		const { name, value } = e.target;
		setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleLogin(e) {
		e.preventDefault();

		setSubmitted(true);
		if (username && password) {
			// get return url from location state or default to home page
			const { from } = location.state || { from: { pathname: "/" } };
			console.log('from: ' + from)
			dispatch(userActions.login(username, password, from));
		}
	}

	function getUrl() {
		return window.location.protocol + '//' + window.location.host + '/supplement'
	}

	return (
		<Container className="auth-wrapper">
			<Row>
				<Col md={{ span: 4, offset: 4 } }>
					<Jumbotron className="auth-inner">
						<AlertComponent />
						<img src={environmentService.getLogo(environment)} style={{width: '100%', marginBottom: '50px'}}/>
						<Form onSubmit={handleLogin}>
							<FormGroup controlId="formBasicUsername">
								<FormLabel>
									{t('login.username')}
								</FormLabel>
								<FormControl
								type="text"
								className="form-control"
								name="username"
								value={username}
								onChange={handleChange}
								validations={[required]}
								/>
							</FormGroup>

							<FormGroup controlId="formBasicPassword">
								<FormLabel>
									{t('login.password')}
								</FormLabel>
								<FormControl
								type="password"
								className="form-control"
								name="password"
								value={password}
								onChange={handleChange}
								validations={[required]}
								/>
							</FormGroup>

							<FormGroup controlId="formLogin">
								<button
								className="btn btn-primary btn-block"
								disabled={loggingIn}
								>
								<span>{t('login.login')}</span>
								{loggingIn && (
									<>
									&nbsp;
									<span className="spinner-border spinner-border-sm"></span>
									</>
								)}
								</button>
							</FormGroup>
						</Form>
						<p style={{marginTop: 50}}>
							{parse(t('login.ticket', {url: getUrl()}))}
						</p>
					</Jumbotron>
				</Col>
			</Row>
		</Container>
	);
}

export default Login;