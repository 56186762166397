import api from './api.service'

export const privacyPolicyService = {
    getAll,
    save,
    update,
    _delete
};

const BASE_URL = '/privacyPolicies'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function save(privacyPolicy) {
    return api.post(BASE_URL, privacyPolicy)
}

function update(id, privacyPolicy) {
    return api.put(BASE_URL + "/" + id, privacyPolicy)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
