import api from './api.service'

export const ticketMemberService = {
    save,
    _delete
};

const BASE_URL = '/ticketMembers'

function save(ticketEvent) {
    return api.post(BASE_URL, ticketEvent)
}

function _delete(ticket, user) {
    return api.delete(BASE_URL + "/" + ticket + ":" + user)
}
