import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { userService, roleService, authService } from '../../_services'
import { history } from '../../_helpers';

function SwitchUser() {

	const [users, setUsers] = useState([]);
	const [roles, setRoles] = useState([]);
	const [userId, setUserId] = useState(null);
    const {t} = useTranslation('common');

    useEffect(() => {
		userService.getAll().then(data => {
			data = data.filter(user => user.id != 1)
			data = data.sort((a, b) => a.username.localeCompare(b.username))
            setUsers(data)
            if (data.length > 0) {
                setUserId(data[0].id)
            }
        })
        roleService.getAll().then(data => {
			setRoles(data)
        })
    }, [])

    const onClick = () => {
        if (userId) {
			authService.switchUser(userId).then(response => {
				history.push("/")
			})
        }
    }

    const handleChange = (event) => {
        setUserId(event.target.value)
    }

    const formatRoles = (user) => {
        var userRoles = []
        user.userRoles.map(userRole => {
            var role = roles.find(role => role.id == userRole.role)
            if (role) {
                userRoles.push(role.authority)
            }
        })
        return userRoles.join(', ')
    }

	return (
		<div>
			<h2>
                {t('switchuser.label')}
            </h2>
            { users.length > 0 && roles.length > 0 &&
	            <Row>
					<Col md="4">
						<Form.Control required as="select" name="user" onChange={handleChange}>
			                {users.map(user => (
			                    <option key={user.id} value={user.id}>{user.username} ({user.customerName}, {formatRoles(user)})</option>
			                ))}
			            </Form.Control>
			            <div className="clearfix" style={{marginTop: '5px'}}>
				            <div style={{'float': 'right'}}>
								<Button onClick={onClick}>{t('switchuser.switchuser')}</Button>
							</div>
			            </div>
		            </Col>
				</Row>
			}
        </div>
    )
}

export default SwitchUser;