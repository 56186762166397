import React, {useState, useEffect} from 'react';
import CrudTable from '../table/crudtable'
import { introTextService, authService } from '../../_services'
import IntroTextForm from './introtextform.component'
import { useTranslation } from "react-i18next";

function IntroText() {

	const {t} = useTranslation('common');

	const initialState = {
		customerId: 1,
		language: 'SV',
		items: []
	}

	useEffect(() => {
    }, [])

	const columns = [
		{
			dataField: 'customerName',
			text: t('introtext.columns.customer'),
			sort: true
		},
		{
			dataField: 'language',
			text: t('introtext.columns.language'),
			sort: true
		}
	]

  return (
    <div>
      <h2>
        {t('introtext.label')}
      </h2>
	    <CrudTable
	        service={introTextService}
	        columns={columns}
	        initialState={initialState}
	        form={<IntroTextForm />}
	        remote={{"filter": false, "search": false, "pagination": false}}
	        sort={{dataField: 'customerName', order: 'asc'}}
	        modalSize="xl"
	        creatable={authService.hasRoles(['ROLE_INTRO_TEXT_WRITE'])}
	        editable={authService.hasRoles(['ROLE_INTRO_TEXT_WRITE'])}
	        deletable={authService.hasRoles(['ROLE_INTRO_TEXT_WRITE'])}
	      />
      </div>
  )
}

export default IntroText;