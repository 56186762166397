import React, {useState, useEffect} from 'react';
import CrudTable from '../table/crudtable'
import { formService, authService } from '../../_services'
import FormForm from './formform.component'
import { useTranslation } from "react-i18next";

function Form() {

	const {t} = useTranslation('common');

	const initialState = {
		customerId: -1,
		name: '',
		description: '',
		header: {},
		footer: {},
		recaptcha: true,
		template: false,
		items: [],
		languages: ['SV']
	}

	useEffect(() => {
    }, [])

	const descriptionFormatter = function(cell) {
	    return cell || '-'
	}

	const columns = [
		{
			dataField: 'customerName',
			text: t('form.columns.customer'),
			sort: true,
			hidden: !authService.hasRoles(['ROLE_CUSTOMER_READ'])
		},
		{
			dataField: 'name',
			text: t('form.columns.name'),
			sort: true
		},
		{
			dataField: 'description',
			text: t('form.columns.description'),
			formatter: descriptionFormatter
		}
	]

  return (
    <div>
      <h2>
        {t('form.label')}
      </h2>
	    <CrudTable
	        service={formService}
	        columns={columns}
	        initialState={initialState}
	        form={<FormForm />}
	        remote={{"filter": false, "search": false, "pagination": false}}
	        sort={{dataField: 'customerName', order: 'asc'}}
	        modalSize="xl"
	        creatable={authService.hasRoles(['ROLE_FORM_WRITE'])}
	        editable={authService.hasRoles(['ROLE_FORM_WRITE'])}
	        deletable={authService.hasRoles(['ROLE_FORM_WRITE'])}
	      />
      </div>
  )
}

export default Form;