import React, {useState, useEffect} from 'react';
import CrudTable from '../table/crudtable'
import { ticketService, authService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from "moment";

function TicketList() {

	const {t} = useTranslation('common');

	useEffect(() => {
    }, [])

	const nameFormatter = function(cell, row) {
		return <div>
			<a href={'ticket/' + row.id}>{cell}</a>
		</div>
	}

	const dateFormatter = function(cell, row) {
		return moment(cell).format('YYYY-MM-DD HH:mm')
	}

	const membersFormatter = function(cell, row) {
		if (cell && cell.length > 0) {
			return cell.map(user => user.username).join(', ')
		}
		return '-'
	}

	const eventFormatter = function(cell, row) {
		var type = null
		if (cell && cell.length > 0) {
			type = cell[cell.length-1].type
		}
		if (type) {
			switch(type) {
				case 'RECEIVED':
					return (t('event.types.received'))
				case 'UNDER_INVESTIGATION':
					return (t('event.types.underinvestigation'))
				case 'RESOLVED':
					return (t('event.types.resolved'))
				case 'READ_BY_WHISTLEBLOWER':
					return (t('event.types.readbywhistleblower'))
				default:
			}
		}
	    return '-'
	}

	const columns = [
		{
			dataField: 'name',
			text: t('ticket.columns.name'),
			formatter: nameFormatter,
			sort: true,
			filterValue: (cell, row) => row.name
		},
		{
			dataField: 'dateCreated',
			text: t('ticket.columns.datecreated'),
			formatter: dateFormatter,
			sort: true,
			filterValue: dateFormatter
		},
		{
			dataField: 'members',
			text: t('ticket.columns.members'),
			formatter: membersFormatter,
			sort: true,
			filterValue: membersFormatter
		},
		{
			dataField: 'events',
			text: t('ticket.columns.event'),
			formatter: eventFormatter,
			sort: true,
			filterValue: eventFormatter
		}
	]

  return (
    <div>
      <h2>
        {t('ticket.label')}
      </h2>
    <CrudTable
        service={ticketService}
        columns={columns}
        remote={{"filter": false, "search": false, "pagination": false}}
        sort={{dataField: 'dateCreated', order: 'desc'}}
        modalSize="xl"
        creatable={false}
        editable={false}
        deletable={authService.hasRoles(['ROLE_FORM_WRITE'])}
      />
      </div>
  )
}

export default TicketList;