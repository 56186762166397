import React, {useState, useEffect} from 'react';
import CrudTable from '../table/crudtable'
import { privacyPolicyService, authService } from '../../_services'
import PrivacyPolicyForm from './privacypolicyform.component'
import { useTranslation } from "react-i18next";

function PrivacyPolicy() {

	const {t} = useTranslation('common');

	const initialState = {
		customerId: 1,
		language: 'SV',
		sections: []
	}

	useEffect(() => {
    }, [])

	const columns = [
		{
			dataField: 'customerName',
			text: t('privacypolicy.columns.customer'),
			sort: true
		},
		{
			dataField: 'name',
			text: t('privacypolicy.columns.name'),
			sort: true
		},
		{
			dataField: 'language',
			text: t('privacypolicy.columns.language'),
			sort: true
		}
	]

  return (
    <div>
      <h2>
        {t('privacypolicy.label')}
      </h2>
	    <CrudTable
	        service={privacyPolicyService}
	        columns={columns}
	        initialState={initialState}
	        form={<PrivacyPolicyForm />}
	        remote={{"filter": false, "search": false, "pagination": false}}
	        sort={{dataField: 'customerName', order: 'asc'}}
	        modalSize="xl"
	        creatable={authService.hasRoles(['ROLE_PRIVACY_POLICY_WRITE'])}
	        editable={authService.hasRoles(['ROLE_PRIVACY_POLICY_WRITE'])}
	        deletable={authService.hasRoles(['ROLE_PRIVACY_POLICY_WRITE'])}
	      />
      </div>
  )
}

export default PrivacyPolicy;