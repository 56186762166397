import api from './api.service'

export const userService = {
    getAll,
    save,
    update,
    _delete,
    showSelf,
    updateSelf
};

const BASE_URL = '/users'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function save(user) {
    return api.post(BASE_URL, user)
}

function update(id, user) {
    return api.put(BASE_URL + "/" + id, user)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function showSelf() {
    return api.get("/user/showSelf/")
}

function updateSelf(id, email, sendTicketAlerts) {
    return api.put("/user/updateSelf/" + id + "?email=" + email + "&sendTicketAlerts=" + sendTicketAlerts)
}
