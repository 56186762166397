import React from 'react'
import {authService} from '../_services/'

const Access = ({ children, permission }) => {
    const hasPermission = authService.hasRoles(permission);
    return (
      <>
        {hasPermission && children}
      </>
    );
  };

export default Access