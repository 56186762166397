import React, {useState} from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import ModalForm from './modal'
import { useTranslation } from "react-i18next";

function EditButton(props) {

    const [show, setShow] = useState(false)
    const {t} = useTranslation('common');

    function handleConfirm (data) {
        setShow(false)
        props.confirmClick(data)
    }

    const handleShow = () => {
		setShow(true)
		document.body.className = "modal-open"
		document.body.style = 'overflow: hidden;';
    }

    const handleClose = () => {
		setShow(false)
		document.body.className = ""
		document.body.style = '';
    }

    return (
        <>
        <Button 
            onClick={handleShow}
            title={t('crudtable.edit')}
            size="sm" 
            color="info" 
            variant="outline-secondary">
            <FontAwesomeIcon icon={faEdit}/>
        </Button>
        <ModalForm
            data={props.data}
            show={show}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
            modalHeader={t('crudtable.edit')}
            modalBody={props.form}
            size={props.size}
        />
        </>
    )

}

export default EditButton