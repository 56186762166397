//import 'bootstrap/dist/css/bootstrap.css';
import './css/bootstrap.min.css'
import './css/App.css'
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from 'react-redux';
import { store } from './_helpers';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import { LoadScript } from '@react-google-maps/api';

i18n.changeLanguage()

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<LoadScript googleMapsClientId="gme-iqmtelswedenab">
	        <Provider store={store}>
	            <App />
	        </Provider>
        </LoadScript>
    </I18nextProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();