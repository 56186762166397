import api from './api.service'

export const environmentService = {
    get,
    getTitle,
    getLogo
};

const BASE_URL = '/environment'

function get() {
    return api.get(BASE_URL)
}

function getTitle(environment) {
	switch (environment) {
	    case 'development':
	        return 'RDLAW'
		case 'production':
	        return 'RDLAW'
	    case 'production_tretorn':
	        return 'Tretorn'
	}
	return 'RDLAW'
}

function getLogo(environment) {
	switch (environment) {
	    case 'development':
	        return "/logos/development.png"
		case 'production':
	        return "/logos/production.png"
	    case 'production_tretorn':
	        return "/logos/tretorn.png"
	}
	return "/logos/development.png"
}
