import React, { useState, useEffect } from 'react';
import { authService, userService } from '../_services'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { alertActions } from '../_actions/alert.actions'

function Profile() {

	const [user, setUser] = useState({})
	const [validated, setValidated] = useState(false);
	const {t} = useTranslation('common');
	const dispatch = useDispatch();

	useEffect(() => {
		userService.showSelf().then(response => {
			setUser(response)
		})
    }, [])

    const handleEmailChange = (event) => {
        var value = event.target.value
        setUser( prevUser => ({
            ...prevUser,
	        ['email']: value
	    }));
    }

    const handleSendTicketAlertsChange = (event) => {
        var value = event.target.checked
        setUser( prevUser => ({
            ...prevUser,
	        ['sendTicketAlerts']: value
	    }));
    }

    const handleSubmit = (event) => {
	    event.preventDefault();
	    const form = event.currentTarget;
	    if (form.checkValidity() === false) {
	      event.stopPropagation();
	      setValidated(true);
	    } else {
	      userService.updateSelf(user.id, user.email, user.sendTicketAlerts).then(response => {
			dispatch(alertActions.success(t('alerts.updatesuccessful')))
		  })
	      setValidated(false);
	    }
	};

//    const handleSubmit = () => {
//		userService.updateSelf(user.id, user.email, user.sendTicketAlerts).then(response => {
//			console.log(response)
//		})
//    }

  return (
    <Row>
        <Col sm="4">
            <h2>
                {t('profile.label')}
            </h2>
            <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
				<Form.Group controlId="email">
		            <Form.Label>
		                {t('user.columns.email')}
		            </Form.Label>
		            <Form.Control type="email" placeholder={t('user.columns.email')} name="email" value={user.email} onChange={handleEmailChange} />
		        </Form.Group>
		        <Form.Group controlId="sendTicketAlerts">
		            <Form.Check type="checkbox" label={t('user.columns.sendticketalerts')} name="sendTicketAlerts" checked={user.sendTicketAlerts} onChange={handleSendTicketAlertsChange} />
		        </Form.Group>
		        <Button type="submit">
		            {t('profile.update')}
		        </Button>
	        </Form>
        </Col>
    </Row>
  )
}

export default Profile;