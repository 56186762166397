import api from './api.service'

export const ticketService = {
    getAll,
    show,
    save,
    update,
    _delete
};

const BASE_URL = '/tickets'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function show(id) {
    return api.get(BASE_URL + "/" + id)
}

function save(ticket) {
    return api.post(BASE_URL, ticket)
}

function update(id, ticket) {
    return api.put(BASE_URL + "/" + id, ticket)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
