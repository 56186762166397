import api from './api.service'

export const roleService = {
    getAll,
    save,
    update,
    _delete
};

const BASE_URL = '/roles'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function save(role) {
    return api.post(BASE_URL, role)
}

function update(id, role) {
    return api.put(BASE_URL + "/" + id, role)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
